import {commonConfig} from "../../common/js/config";
import {formatNumberString} from "../../common/js/utils";
import '@splidejs/splide/css/sea-green';
import Splide from '@splidejs/splide';

export function setRadioAndUpdateTiers(country) {
    $(`input[type="radio"][value=${country}]`).prop("checked", true)
    updateTiers(country);

}

export function updateTiers(country) {
    const countryPrices = window.estimationsData[country];

    const currencyLocal = commonConfig.currencies[countryPrices.currency];

    countryPrices.tiers.forEach((tier) => {

        const minimum = tier['minimum_ads']
        const tr = $(`.tier-${minimum}`);
        const connectionCostEl = tr.find(".connection-cost");
        const tokensCostEl = tr.find(".tokens-cost");

        const connectionCost = formatNumberString(tier['connection_cost']);
        const tokensCost = formatNumberString(tier['tokens_cost']);

        connectionCostEl.html(connectionCost + " " + currencyLocal + "<sup>*</sup>")
        tokensCostEl.html(tokensCost + " " + currencyLocal + "<sup>**</sup>")

    });

}