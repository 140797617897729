import {commonConfig} from "./config";

export function makeId(len = 8) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < len; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export function debounce(func, delay) {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}

export function isValidEmail(email) {
    // Regular expression to validate an email address
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
}


export function formatBigNumber(number) {
    const abbreviations = ['K', 'M', 'G', 'T', 'P', 'E'];

    let base = 1000;
    let index = -1;

    while (number >= base && index < abbreviations.length - 1) {
        number /= base;
        index++;
    }

    // Проверяем, является ли число целым
    const formattedNumber = number % 1 === 0 ? number : number.toFixed(1);

    return formattedNumber + (index >= 0 ? abbreviations[index] : '');
}

export function isEmptyObject(obj) {
    return (obj === null || obj === undefined || Object.keys(obj).length === 0)
}

export async function getCountry() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                getCountryFromLocation(latitude, longitude)
                    .then(country => {
                        if (commonConfig.countries.includes(country)) {
                            console.log('Страна из геолокации:', country);
                            localStorage.setItem('userCountry', country); // Кэшируем страну
                            resolve(country); // Успешно возвращаем страну
                        } else {
                            reject(new Error('Страна не в списке разрешенных'));
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error); // Отклоняем промис с ошибкой
                    });
            }, error => reject(error)); // Отклоняем промис, если не удаётся получить геопозицию
        } else {
            reject(new Error('Геолокация не поддерживается вашим браузером'));
        }
    });
}

function getCountryFromLocation(latitude, longitude) {
    return new Promise((resolve, reject) => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

        fetch(url, {
            headers: {
                'User-Agent': 'OLX-GPT ytextsocks@gmail.com'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.address) {
                    const country = data.address.country_code.toUpperCase();
                    resolve(country);
                } else {
                    reject(new Error('Не удалось получить данные о местоположении'));
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function useBackupMethod() {
    const country = getOlxRegionBasedOnLanguage();
    console.log('Страна из резервного метода:', country);
    localStorage.setItem('userCountry', country); // Кэшируем страну
}


export function getOlxRegionBasedOnLanguage() {
    let userLang = document.documentElement.lang;
    userLang = userLang.toLowerCase();

    const offsetMinutes = new Date().getTimezoneOffset();

    switch (userLang) {
        case 'pl':
        case 'pl-pl':
            return 'PL';
        case 'pt':
        case 'pt-pt':
            return 'PT';
        case 'bg':
        case 'bg-bg':
            return 'BG';
        case 'ro':
        case 'ro-ro':
            return 'RO';
        case 'uk':
        case 'uk-ua':
            return 'UA';
        case 'kk':
        case 'kk-kz':
            return 'KZ';
        case 'ru':
        case 'ru-ru':
            if (offsetMinutes < -200) {
                return 'KZ'
            }
            return 'UA';
        default:
            return 'UA';
    }
}

export function formatNumberString(numberString) {
    const number = parseFloat(numberString);
    if (Number.isInteger(number)) {
        return number.toString();
    } else {
        return numberString;
    }
}

export function disableConsoleLogs() {

    if (process.env.NODE_ENV === 'production') {
        if (typeof console === "undefined") {
            window.console = {};
        }
        const methods = ["log", "debug"];
        for (let i = 0; i < methods.length; i++) {
            console[methods[i]] = function () {
            };
        }
    }

}

export function checkForCollapsibles() {
    let hasCollapsibles = false;
    $('.transform').each(function () {
        const targetId = $(this).attr('data-bs-target');
        const targetElement = $(targetId);
        if (targetElement.hasClass('show')) {
            hasCollapsibles = true;
            return false; // Прерываем цикл, если нашли открытый элемент
        }
    });
    return hasCollapsibles;
}
