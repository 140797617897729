import {getCountry, getOlxRegionBasedOnLanguage} from "../../common/js/utils";
import {commonConfig} from "../../common/js/config";
import {setRadioAndUpdateTiers, updateTiers} from "./dom-manipulation";

export function handleEvents() {

    $("#slider-arrow-right").on("click", function (event) {
        const allVisibleCards = $('.step-slider-card.step-slider-card-visible');

        const firstVisibleCard = allVisibleCards.first();
        $("#slider-arrow-left").prop("disabled", false);

        if (allVisibleCards.length < 2) {
            $(this).prop("disabled", true);
        }

        // Удаляем класс, делающий элемент видимым, и добавляем класс начала анимации выхода
        firstVisibleCard.removeClass('step-slider-card-visible').addClass('step-slider-card-item-exit-done');

        // Отслеживаем завершение анимации


    });

    $("#slider-arrow-left").on("click", function (event) {

        const allExitCards = $('.step-slider-card.step-slider-card-item-exit-done');

        const lastExitCard = allExitCards.last();
        $("#slider-arrow-right").prop("disabled", false);

        if (allExitCards.length < 2) {
            $(this).prop("disabled", true);
        }

        // Удаляем класс, делающий элемент видимым, и добавляем класс начала анимации выхода
        lastExitCard.removeClass('step-slider-card-item-exit-done').addClass('step-slider-card-item-enter-active');

        // Отслеживаем завершение анимации
        lastExitCard.one('transitionend', function () {
            // По завершении анимации заменяем класс активной анимации на класс завершения анимации
            $(this).removeClass('step-slider-card-item-enter-active').addClass('step-slider-card-visible');
        });


    });

    $(".country-radio").on("change", function (event) {
        const country = $(this).data("country");
        updateTiers(country);
        localStorage.setItem('userCountry', country);

    });


}

export async function defineCountry() {
     let country = localStorage.getItem('userCountry');

    if (!country) {
        country = getOlxRegionBasedOnLanguage();
        setRadioAndUpdateTiers(country); // Функция, адаптирующая контент под страну


        // Попытка асинхронного определения более точной страны
        getCountry().then(geoCountry => {

        if (geoCountry && geoCountry !== country) {

            setRadioAndUpdateTiers(geoCountry); // Обновляем контент, если страна отличается
        }
        }).catch(error => {
            localStorage.setItem('userCountry', country);
            console.log(error)
        });
    }
    else {
        setRadioAndUpdateTiers(country); // Функция, адаптирующая контент под страну
    }
}



